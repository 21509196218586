import React from 'react'
import Dog3 from '../../images/dog-illustration-positivity.inline.svg'

export default function Positivity() {
  return (
    <section className="positivity">
      <h3>Positivity</h3>
      <div className="text">
        <div className="inner">DTC Certified Professional Dog Trainer who uses modern and ethical science based training techniques that focus on force-free methods and incorporate positive reinforcement.</div>
      </div>
      <Dog3 />
    </section>
  )
}
