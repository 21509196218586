import { Link } from 'gatsby'
import React from 'react'
import Dog2 from '../../images/dog-illustration-wellness.inline.svg'

export default function Wellness() {
  return (
    <section className="wellness">
      <h3>Wellness</h3>
      <div className="text">
        <div className="inner">Bespoke wellness toolkits to enrich your dog’s lifestyle and ensure their emotional and physical needs are being met.</div>
      </div>
      <Link className="button small" to={'/services#wellness'}>Find out more</Link>
      <Dog2 />
    </section>
  )
}
