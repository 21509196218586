import React from 'react'
import Dog1 from '../../images/dog-illustration-1.inline.svg'

export default function DogBanner() {
  return (
    <section className="dogBanner">
      <Dog1 />
    </section>
  )
}
