import React from 'react'
// Import Swiper React components
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/pagination";
import 'swiper/css/effect-fade';

export default function Slider({slides}) {
  return (
    <Swiper
    modules={[Navigation, EffectFade, Autoplay, Pagination]}
    navigation
    effect='fade'
    loop={true}
    speed={600}
    pagination={true}
    autoHeight={true}
    spaceBetween={50}
    slidesPerView={1}
    autoplay={{
      delay: 8000,
      disableOnInteraction: false,
      pauseOnMouseEnter:true,
    }}
    onSlideChange={() => console.log('slide change')}
    onSwiper={(swiper) => console.log(swiper)}
    paus
  >
    {slides.map(slide => <SwiperSlide><div className="text">{slide.text}</div><div className="name">{slide.name}</div></SwiperSlide>)}
  </Swiper>
  )
}
