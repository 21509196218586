import React from 'react'
import Slider from '../../components/reusable/Slider'

export default function Testimonials() {
  const slides = [
    {
      name: `Grainne, Alfie's mum (Jack Tzu)`,
      text: `"Lucy is a fantastic dog walker. She started walking my dog Alfie when I was heavily pregnant and has continued since! Alfie loves his one on one time with Lucy and the tricks and games she teaches him! He's always very excited to see her and she's very flexible with my inconsistent schedule! Apart from being professional she is caring, kind and a true dog lover, I would highly recommend."`
    },
    {
      name: `Beccy, Henry's mum (French Bulldog)`,
      text: `"Lucy has been walking our dog Henry for almost six months, and we couldn't be happier with the level of care and attention she provides. Henry is a rescue dog that is fearful of strangers, and Lucy was incredibly patient and gentle in getting to know him and understanding his needs. Being a rescue dog, he has his fair share of 'quirks' and Lucy handles him brilliantly. Her focus on positive engagement and breed appropriate enrichment is great for Henry's wellbeing, and we always have a tired and contented boy after a session. Lucy is reliable and trustworthy, and communicates effectively with us as needed. She is knowledgeable and flexible, and I really believe Henry has benefitted so much from the time spent with her. Lucy has built up a great bond with Henry, and he loves his weekly walks and games! I would not hesitate to recommend her to fellow dog owners, she genuinely cares for my dog as if he were her own."`
    },
    {
      name: `Olivia, Nellie's mum (Staffordshire Bull Terrier)`,
      text: `"Lucy is a fantastic dog walker. She started walking my dog Alfie when I was heavily pregnant and has continued since! Alfie loves his one on one time with Lucy and the tricks and games she teaches him! He’s always very excited to see her and she’s very flexible with my inconsistent schedule! Apart from being professional she is caring, kind and a true dog lover, I would highly recommend."`
    },
    {
      name: `Jas, Cole's mum (Labrador)`,
      text: `"Cole was only a puppy (5 month old black Lab) when Lucy started doing solo walking with him. I wanted someone who I could trust to walk him responsibly and continue with the training I had already started with him. I am impressed with Lucy’s caring and efficient manner and would not hesitate in recommending her services."`
    },
  ]
  return (
    <section className="testimonials">
      <h3>Testimonials</h3>
      <Slider slides={slides} />
    </section>
  )
}
