import React from 'react'
import Map from '../../images/map.inline.svg'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function MapSection({map, mapMobile}) {
  return (
    <section className="map">
      <h3>Walking</h3>
      <Map className="desktop" />
      <GatsbyImage className="mobile" image={mapMobile} alt={'Dog Walking Area Map'} />
      <div className="notification-bar">Please note this map is not exhaustive, please do not hesitate to contact if you live in North Leeds</div>
    </section>
  )
}
