import { GatsbyImage } from 'gatsby-plugin-image';
import React, {useEffect, useState} from 'react'
import { useParallax, useParallaxController } from 'react-scroll-parallax';
import ScrollIndicator from './ScrollIndicator';


export default function ParallaxImage({image, alt, height, width, speed, offset, className, showScrollIndicator}) {

  const [isDisabled, setIsDisabled] = useState(false)


  const { ref } = useParallax({ speed:speed, disabled: isDisabled});
  const parallaxController = useParallaxController();
  
  const checkWindowSize = () => {
    if(typeof window !== 'undefined'){
      const windowWidth = window.innerWidth
      if(windowWidth < 600){
        setIsDisabled(true)
      }else{
        setIsDisabled(false)
      }
    }
  }

  useEffect(() => {
    parallaxController.update()
    
    checkWindowSize()

    if(typeof window !== 'undefined'){
      window.addEventListener('resize', checkWindowSize)
    }
  })
  
  return  <figure className={`width-${width} height-${height} parallax ${className}`}>
            <div className='parallax--wrapper' ref={ref}>
              <GatsbyImage onLoad={() => parallaxController.update()} className="parallax--image" image={image} alt={alt} />
            </div>
            {showScrollIndicator === true ? <ScrollIndicator /> : ""}
          </figure>;
}
