import React from 'react'
import {IoChevronDownCircleSharp} from 'react-icons/io5'
export default function ScrollIndicator() {
  return (
    <div className='scrollIndicator'>
      <span>Scroll</span>
      <IoChevronDownCircleSharp />
    </div>
  )
}
