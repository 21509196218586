import { graphql, Link } from 'gatsby'
import React from 'react'
import ParallaxImage from '../components/reusable/ParallaxImage'
import PinkBanner from '../page-components/home/PinkBanner'
import DogBanner from '../page-components/home/DogBanner'
import MapSection from '../page-components/home/MapSection'
import Wellness from '../page-components/home/Wellness'
import Positivity from '../page-components/home/Positivity'
import Testimonials from '../page-components/home/Testimonials'
import LiveLifeLikeTed from '../page-components/home/LiveLifeLikeTed'
import Seo from '../components/Seo'

export default function Homepage({data}) {
  return (
    <div className='home'>
      <Seo 
        description={'Bespoke dog walking service covering Chapel Allerton and surrounding areas of North Leeds.'}
        image={'/og.jpg'}
        title={'TED: The Enriched Dog | Dog Walking Leeds'}
      />
      <ParallaxImage 
        image={data.heroImage.childImageSharp.gatsbyImageData}
        alt={'TED: The Enriched Dog Hero Image'}
        height={'100vh'}
        width={'100'}
        speed={-20}
        offset={0}
        className={'hero'}
        showScrollIndicator={true}
      />
      <PinkBanner />
      <DogBanner />
      <MapSection map={data.map.childImageSharp.gatsbyImageData} mapMobile={data.mapMob.childImageSharp.gatsbyImageData} />
      <Wellness />
      <Positivity />
      <Testimonials />
      <LiveLifeLikeTed />
      <ParallaxImage 
        image={data.footerHomepage.childImageSharp.gatsbyImageData}
        alt={'TED: The Enriched Dog Hero Image'}
        height={'100vh'}
        width={'100'}
        speed={0}
        offset={0}
      />
    </div>
  )
}

export const HomepageQuery = graphql`
  query HomepageQuery { 
    heroImage:  file(relativePath:{eq:"hero.jpg"}) {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
    footerHomepage: file(relativePath:{eq:"footerHomepage.jpg"}) {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
    map: file(relativePath:{eq:"map.jpg"}) {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
    mapMob: file(relativePath:{eq:"map-mobile.jpg"}) {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
  }
`